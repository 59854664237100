<template>
  <div class="send-mail-first-reservation">
    <div class="page-title">
      <base-page-title>{{
        $t('sendMailFirstReservation.pageTitle')
      }}</base-page-title>
    </div>
    <div class="reservation-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="warning">{{ $t('sendMailFirstReservation.textWarning') }}</div>
    <div class="message">{{ message }}</div>
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import PreventBack from '@/components/mixins/PreventBack'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'

export default {
  name: 'SendMailFirstReservation',

  components: {
    BasePageTitle,
    ProgressBar
  },

  mixins: [PreventBack],

  data() {
    const { autoConfirmFlg } = this.$store.getters[
      'reservationSetting/getReservationSetting'
    ]
    const scenario = this.$store.getters['auth/scenario']
    return {
      progressBarData: createProgressBarDataForReservation(scenario, 6),
      registrationType: autoConfirmFlg ? '予約' : '予約申請'
    }
  },

  computed: {
    message() {
      const file = 'sendMailFirstReservation'
      const registrationType = this.registrationType
      const texts = [
        this.$t(`${file}.textSentEmail`, { registrationType }),
        this.$t(`${file}.textClickUrl`, { registrationType }),
        this.$t(`${file}.textCloseWindow`)
      ]
      const message = texts.join('\n')
      return message
    }
  }
}
</script>

<style lang="scss" scoped>
.send-mail-first-reservation {
  padding: 0 20px;
  > .page-title {
    margin-top: 70px;
  }
  > .reservation-progress-bar {
    margin: 28px 0 40px;
  }
  > .warning {
    @include font-size(20);
    font-weight: bold;
    color: #{$plus_red};
  }
  > .message {
    @include font-size(18);
    font-weight: bold;
    white-space: pre-wrap;
  }
}
</style>
